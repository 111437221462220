.sc__input-container {
  width: 100%;
  font-family: 'Manrope';
}

.sc__input-label {
  display: block;
  height: 20px;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  -webkit-font-smoothing: subpixel-antialiased;
}

.sc__input {
  width: 100%;
  box-sizing: border-box;
  padding: 9px 13px;
  color: #000000;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.sc__input:focus {
  outline: #4840bd solid 1px !important;
}
